import React, { Fragment, useEffect, useState } from "react";
import { AppWrapper } from "../AppWrapper";
import Skeleton from "@material-ui/lab/Skeleton";
import { Typography, Box, Grid } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import solarizedDark from "react-syntax-highlighter/dist/esm/styles/hljs/solarized-dark";
import { makeStyles } from "@material-ui/core/styles";

const ParagraphRenderer = ({ node, ...props }: any) => {
  return (
    <Box m={1}>
      <Typography variant={"body1"}>{props.children}</Typography>
    </Box>
  );
};

const HeadingRenderer = ({ node, ...props }: any) => {
  let variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

  switch (props.level) {
    case 1:
      variant = "h1";
      break;
    case 2:
      variant = "h2";
      break;
    case 3:
      variant = "h3";
      break;
    case 4:
      variant = "h4";
      break;
    case 5:
      variant = "h5";
      break;
    case 6:
      variant = "h6";
      break;
    default:
      variant = "h6";
      break;
  }

  return (
    <Box m={1} mt={3}>
      <Typography variant={variant}>{props.children}</Typography>
    </Box>
  );
};

const ListItemRenderer = ({ node, ...props }: any) => {
  return (
    <li>
      <Typography variant={"body1"}>{props.children}</Typography>
    </li>
  );
};

const useStyles = makeStyles({
  skeleton: {
    width: "100%",
  },
});

export interface BlogProps {
  articlePaths: string[];
}

export const Blog: React.FC<BlogProps> = ({ articlePaths }) => {
  const styles = useStyles();

  const [articles, setArticles] = useState<undefined | string[]>(undefined);

  useEffect(() => {
    const articleFuture = articlePaths.map((ap) =>
      fetch(ap).then((res) => res.text())
    );

    Promise.all(articleFuture).then((texts) => {
      setArticles(texts);
    });
  }, [articlePaths]);

  return (
    <AppWrapper>
      <Box mb={3} ml={1}>
        <Typography variant={"h6"}>
          Views expressed here are my own and not that of Spotify.
        </Typography>
      </Box>
      <Box mb={5}>
        {articles === undefined && (
          <Box mt={1}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item className={styles.skeleton}>
                <Typography variant="h1">
                  <Skeleton />
                </Typography>
              </Grid>
              {[...Array(20)].map((n, i) => {
                return (
                  <Grid item key={i} className={styles.skeleton}>
                    <Skeleton />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
        {articles &&
          articles.map((article, i) => {
            return (
              <Fragment key={i}>
                <ReactMarkdown
                  components={{
                    p: ParagraphRenderer,
                    h1: HeadingRenderer,
                    h2: HeadingRenderer,
                    h3: HeadingRenderer,
                    h4: HeadingRenderer,
                    h5: HeadingRenderer,
                    h6: HeadingRenderer,
                    li: ListItemRenderer,
                    code({ node, inline, className, children, ...props }) {
                      const match = /language-(\w+)/.exec(className || "");
                      return (
                        <Box m={1} mt={3}>
                          {!inline && match ? (
                            <SyntaxHighlighter
                              {...props}
                              children={String(children).replace(/\n$/, "")}
                              style={solarizedDark}
                              language={match[1]}
                              PreTag="div"
                            />
                          ) : (
                            <code {...props} className={className}>
                              {children}
                            </code>
                          )}
                        </Box>
                      );
                    },
                  }}
                >
                  {article}
                </ReactMarkdown>
              </Fragment>
            );
          })}
      </Box>
    </AppWrapper>
  );
};
