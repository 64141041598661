import { Job } from "../types";

const jobs: Job[] = [
  {
    jobTitle: "Senior Software Engineer at Spotify",
    jobDescription: [
      "Working as a Full Stack DevOps engineer. Java, JavaScript, Scala, Python, Microservices, Scalability, Postgres, React, Kubernetes.",
    ],
    time: "present",
    where: "New York, USA",
    projects: [
      "Large scale deprecation of critical infrastructure, reducing tech debt and reducing cloud costs",
      "Rearchitecting of deployment platform, increasing % successfully deploys, increasing developer productivity",
      "Backstage Kubernetes plugin, used by many companies and commercial solutions",
    ],
  },
  {
    jobTitle: "Senior Software Engineer at Spotify",
    jobDescription: [
      "Working as a Full Stack DevOps engineer. Java, JavaScript, Scala, Python, Microservices, Scalability, Postgres, React, Kubernetes.",
    ],
    time: "2 years",
    where: "London, UK",
    projects: [
      "Large scale Kubernetes migration",
      "Implementing automated canary analysis, reducing incidents, increasing deployment safety",
    ],
  },
  {
    jobTitle: "Software Engineer at Spotify",
    jobDescription: [
      "Working as a Full Stack DevOps engineer. Java, JavaScript, Scala, Python, Microservices, Scalability, Postgres, React, Kubernetes.",
    ],
    time: "1 year, 8 Months",
    where: "London, UK",
    projects: [
      "Rearchitecting of product catalog, increasing agility of business and e-commerce platform",
      "Developer tools for Kubernetes",
    ],
  },
  {
    jobTitle: "Senior Software Engineer at The Financial Times",
    jobDescription: [
      "Identity management platform, cms access decisions, commerce, payments. Developer. Java, JavaScript, Scala, Go, Dropwizard, Node etc... Cassandra, Kafka, AWS, Kubernetes, Heroku, DevOps, High availability, continuous delivery, git and Agile Methodologies.",
      "It was in this role that I developed my passion for Kubernetes, developing many Kubernetes native applications to streamline deployment, microservice creation and overall developer experience.",
    ],
    time: "1 year",
    where: "London, UK",
    projects: [
      "Large scale Kubernetes migration",
      "Implementing Kubernetes platform",
    ],
  },
  {
    jobTitle: "Software Engineer at The Financial Times",
    jobDescription: [
      "Identity management platform, cms access decisions, commerce, payments. Developer. Java, JavaScript, Scala, Go, Dropwizard, Node etc... Cassandra, Kafka, AWS, Kubernetes, Heroku, DevOps, High availability, continuous delivery, git and Agile Methodologies.",
    ],
    time: "2 years",
    where: "London, UK",
    projects: ["Rearchitecting identity platform", "Login for Google AMP"],
  },
  {
    jobTitle: "Associate Software Engineer at CyberSource (A VISA company)",
    jobDescription: [
      "Java Developer. Experience of various java frameworks and libraries such as spring, jersey, guava, jdbc, quartz, jackson etc... Gained skills in BASH, SQL, continuous delivery and Agile methodologies.",
    ],
    time: "2 years",
    where: "Belfast, UK",
    projects: [],
  },
  {
    jobTitle: "Product Placement Engineer at KANA",
    jobDescription: [
      "Gained skills in Agile Development Practices, developing enterprise-level software, Java, JavaScript and using Linux systems. KANA focuses on customer relationship management software, and so I was able to work on multiple CRM products.",
    ],
    time: "1 year",
    where: "Belfast, UK",
    projects: [],
  },
];

export default jobs;
