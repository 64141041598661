import React from "react";
import { Link } from "react-router-dom";

const NoMatch: React.FC = () => {
  return (
    <div style={{ marginTop: "15px", marginBottom: "75px" }}>
      <Link to={"/"}>Couldn't find what you're looking for?</Link>
    </div>
  );
};

export default NoMatch;
