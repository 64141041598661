import React from "react";
import { Media } from "../types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  actionArea: {
    minHeight: "31rem",
  },
  media: {
    height: "10rem",
  },
});

interface MediaCardProps {
  media: Media;
  children?: React.ReactNode;
}

export const MediaCard = ({ media }: MediaCardProps) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title={media.type} />
      <CardActionArea href={media.link} className={classes.actionArea}>
        <CardMedia
          className={classes.media}
          image={media.img}
          title={media.imgAlt}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {media.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {media.subtitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
