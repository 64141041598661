import me from "../assets/me.jpeg";
import React from "react";
import {
  Container,
  Typography,
  Grid,
  Drawer,
  Box,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles({
  me: {
    borderRadius: "10em",
    width: "100%",
    height: "100%",
  },
  name: {
    textAlign: "left",
  },
  link: {
    color: "black",
  },
  drawer: {
    minWidth: "15rem",
  },
  button: {
    width: "2em",
    height: "2em",
    color: "black",
  },
});

export const AppWrapper = ({ children }: { children?: React.ReactNode }) => {
  const styles = useStyles();

  const [drawerOut, setDrawerOut] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOut(open);
    };

  return (
    <Container maxWidth="lg">
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={12} sm={3}>
          <Box>
            <IconButton
              className={styles.button}
              color="primary"
              aria-label="menu"
              component="span"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon className={styles.button} />
            </IconButton>
            <Drawer
              anchor={"left"}
              open={drawerOut}
              onClose={toggleDrawer(false)}
            >
              <Box m={3} className={styles.drawer}>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="center"
                  spacing={5}
                >
                  <Grid item>
                    <Link to={"/"} onClick={toggleDrawer(false)}>
                      <Typography variant={"h6"} className={styles.link}>
                        Home
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Typography variant={"h6"}>Blog</Typography>
                  </Grid>
                  <Grid item>
                    <Link
                      to={"/blog/kubernetes-clients-pt2"}
                      onClick={toggleDrawer(false)}
                    >
                      <Typography variant={"h6"}>
                        Kubernetes Go Clients pt2
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      to={"/blog/kubernetes-clients-pt1"}
                      onClick={toggleDrawer(false)}
                    >
                      <Typography variant={"h6"}>
                        Kubernetes Go Clients pt1
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to={"/blog/walk"} onClick={toggleDrawer(false)}>
                      <Typography variant={"h6"}>Walk, don’t sprint</Typography>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to={"/blog/rot"} onClick={toggleDrawer(false)}>
                      <Typography variant={"h6"}>
                        Why old code can be dangerous
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Drawer>
          </Box>
        </Grid>
        <Grid item xs={4} sm={2}>
          <Box m={1}>
            <img className={styles.me} src={me} alt={"me"} />
          </Box>
        </Grid>
        <Grid item xs={8} sm={5}>
          <Typography className={styles.name} variant={"h2"}>
            Matthew Clarke
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="h3">Senior Software Engineer</Typography>
      <br />
      {children}
    </Container>
  );
};
