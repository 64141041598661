import React, { Fragment } from "react";
import { AppWrapper } from "../AppWrapper";
import { Typography, Box, Grid } from "@material-ui/core";
import { MediaCard } from "./MediaCard";
import mediaData from "../data/media";
import jobData from "../data/jobs";
import educationData from "../data/education";
import { topLineExpertise, expertise } from "../data/expertise";
import { Media, Job, Expertise, Education } from "../types";

const toExpertiseValueListItem = (v: string, i: number) => {
  return (
    <li key={i}>
      <Typography variant={"body2"}>{v}</Typography>
    </li>
  );
};

const toExpertiseGridElement = (e: Expertise, i: number) => {
  return (
    <Grid item key={i} xs>
      <Typography variant={"h6"}>{e.title}</Typography>
      <ul>{e.values.map(toExpertiseValueListItem)}</ul>
    </Grid>
  );
};

const toJob = (
  { jobTitle, jobDescription, time, where, projects }: Job,
  i: number
) => {
  return (
    <Fragment key={i}>
      <br />
      <Typography variant={"h6"}>{jobTitle}</Typography>
      <Box ml={1} mb={1}>
        <Typography variant={"subtitle2"}>
          {where} - {time}
        </Typography>
      </Box>
      <Typography variant={"body1"}>{jobDescription}</Typography>
      {projects.length > 0 && (
        <>
          <br />
          <Typography variant={"subtitle2"}>Notable projects:</Typography>
          <ul>
            {projects.map((p) => (
              <li key={p}>
                <Typography variant={"body2"}>{p}</Typography>
              </li>
            ))}
          </ul>
        </>
      )}
    </Fragment>
  );
};

const toEducation = ({ title, subtitle }: Education, i: number) => {
  return (
    <Grid item key={i} xs>
      <Typography variant={"h6"}>{title}</Typography>
      <Typography variant={"subtitle1"}>{subtitle}</Typography>
    </Grid>
  );
};

const toMedia = (media: Media, i: number) => {
  return (
    <Grid item key={i} xs={6} lg={2}>
      <MediaCard media={media} />
    </Grid>
  );
};

interface SectionProps {
  title: string;
  children?: React.ReactNode;
}

const Section = ({ title, children }: SectionProps) => {
  return (
    <>
      <Box mb={1} mt={1}>
        <Typography variant={"h5"}>{title}</Typography>
        <Box ml={1}>{children}</Box>
      </Box>
    </>
  );
};

export const Profile: React.FC = () => {
  return (
    <AppWrapper>
      <Section title={"About me"}>
        <Typography variant={"body1"}>
          Fullstack Senior Software Engineer at Spotify in New York, with a
          first class honours degree (BEng) in Computer Science from Queen's
          University Belfast.
        </Typography>
        <br />
        <Typography variant={"body1"}>
          I specialize in building developer tools, increasing developer
          productivity and developer happiness.
        </Typography>
        <br />
        <Typography variant={"h6"}>My focus</Typography>
        <ul>
          {topLineExpertise.map((p) => (
            <li key={p}>
              <Typography variant={"body1"}>{p}</Typography>
            </li>
          ))}
        </ul>
      </Section>
      <br />
      <Section title={"In the media"}>
        <Box mb={1} mt={1}>
          <Grid container spacing={2}>
            {mediaData.map(toMedia)}
          </Grid>
        </Box>
      </Section>
      <br />
      <Section title={"Professional experience"}>{jobData.map(toJob)}</Section>
      <br />
      <Section title={"Education"}>
        <Grid container spacing={2}>
          {educationData.map(toEducation)}
        </Grid>
      </Section>
      <br />
      <Section title={"Expertise"}>
        <Grid container spacing={2}>
          {expertise.map(toExpertiseGridElement)}
        </Grid>
      </Section>
      <br />
      <Section title={"Contact me"}>
        <a href="https://uk.linkedin.com/in/matthewclarke47?">LinkedIn</a>
      </Section>
      <br />
      <br />
    </AppWrapper>
  );
};
