import { Education } from "../types";

const education: Education[] = [
  {
    title: "Queen's University Belfast",
    subtitle: "BEng Computer Science - First Class Honours",
  },
];

export default education;
