import { Media } from "../types";
import kPodcast from "../assets/k-podcast.png";
import myMyBeat from "../assets/me-mybeat.jpeg";
import kubelist from "../assets/kubelist.jpeg";
import k8s from "../assets/k8s.gif";
import newstack from "../assets/newstack.png";
import reddit from "../assets/reddit.png";
import infradev from "../assets/infra-dev.png";
import speaking from "../assets/speaking.jpg";
import docker from "../assets/docker.png";

const media: Media[] = [
  {
    type: "Speaking",
    title: "DockerCon: Demystifying Kubernetes Platforms with Backstage",
    link: "https://www.youtube.com/watch?v=2F6u5W5JW8Q",
    imgAlt: "docker",
    img: docker,
    subtitle:
      "How Spotify's deployments infrastructure team integrated Kubernetes with Backstage to streamline developer productivity and how you can do the same.",
  },
  {
    type: "Speaking",
    title: "Qcon: Demystifying Kubernetes Platforms with Backstage",
    link: "https://qconnewyork.com/presentation/jun2023/demystifying-kubernetes-platforms-backstage",
    imgAlt: "qcon",
    img: speaking,
    subtitle:
      "How Spotify's deployments infrastructure team integrated Kubernetes with Backstage to streamline developer productivity and how you can do the same.",
  },
  {
    type: "Interview",
    title:
      'Interview with Will Larson for the book "Infrastructure Engineering"',
    link: "https://infraeng.dev/matthew-clarke/",
    imgAlt: "infrastructure engineering",
    img: infradev,
    subtitle: "What it's like to be an infrastructure engineer",
  },
  {
    type: "Blog Post",
    title: "Designing a Better Kubernetes Experience for Developers",
    link: "https://engineering.atspotify.com/2021/03/01/designing-a-better-kubernetes-experience-for-developers/",
    imgAlt: "Conatainers moving",
    img: k8s,
    subtitle: "Why Spotify developed a custom Kubernetes UI for our engineers",
  },
  {
    type: "Podcast",
    title: "Kubernetes podcast from Google",
    link: "https://kubernetespodcast.com/episode/136-backstage/",
    imgAlt: "The Kubernetes podcast logo",
    img: kPodcast,
    subtitle:
      "Lee Mills and I talk about Kubernetes, and how I integrated it with Backstage",
  },
  {
    type: "AMA",
    title: "Reddit AMA about Kubernetes DX",
    link: "https://www.reddit.com/r/kubernetes/comments/lwb31v/were_the_engineers_rethinking_kubernetes_at/",
    imgAlt: "Reddit logo",
    img: reddit,
    subtitle:
      "We’re the engineers rethinking Kubernetes at Spotify. Ask us anything!",
  },
  {
    type: "Blog Post",
    title: "My Beat",
    link: "https://engineering.atspotify.com/2021/03/09/my-beat-matt-clarke/",
    imgAlt: "Me",
    img: myMyBeat,
    subtitle: "What an average WFH day looks like for me during covid",
  },
  {
    type: "Podcast",
    title: "Kubelist",
    link: "https://www.heavybit.com/library/podcasts/the-kubelist-podcast/ep-11-backstage-with-lee-mills-and-matt-clarke-of-spotify/",
    imgAlt: "The Kubelist podcast logo",
    img: kubelist,
    subtitle:
      "Lee Mills and I talk about Kubernetes, and how I integrated it with Backstage",
  },
  {
    type: "Blog Post",
    title: "Designing a Better Kubernetes Experience for Developers",
    link: "https://thenewstack.io/design-a-better-kubernetes-experience-for-developers/",
    imgAlt: "Kubernetes in Backstage",
    img: newstack,
    subtitle: "Why Spotify developed a custom Kubernetes UI for our engineers",
  },
];

export default media;
