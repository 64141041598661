import { Expertise } from "../types";

export const topLineExpertise: string[] = [
  "Cost savings by building/optimizing Kubernetes platforms",
  "Increasing developer productivity through Backstage",
  "General cloud cost optimization",
  "Building great platform teams",
];

export const expertise: Expertise[] = [
  {
    title: "Languages and frameworks",
    values: [
      "Java",
      "JavaScript",
      "TypeScript",
      "Go",
      "Scala",
      "Python",
      "SQL",
      "React",
    ],
  },
  {
    title: "Methodologies",
    values: [
      "Fullstack Engineering",
      "CI/CD",
      "Microservices",
      "Scalability",
      "DevOps",
      "GitOps",
      "System design",
      "Infrastructure design",
      "Cost optimization",
      "Declarative Infrastructure",
      "Developer Experience",
      "Progressive Deployment",
    ],
  },
  {
    title: "Infrastructure",
    values: [
      "Kubernetes",
      "GCP",
      "AWS",
      "Terraform",
      "Heroku",
      "Kafka",
      "Cassandra",
      "Postgres",
      "Netlify",
    ],
  },
  {
    title: "Soft Skills",
    values: [
      "Manging software projects",
      "Technical leadership",
      "Public speaking",
      "Communication",
      "Team building",
      "Driving innovation",
      "Building a positive culture",
    ],
  },
];
