import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Profile } from "./Profile";
import NoMatch from "./NoMatch";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Blog } from "./Blog";
import rotPath from "./Blog/articles/ROT.md";
import walkPath from "./Blog/articles/walk.md";
import k8sclient1 from "./Blog/articles/kubernetes-client-1.md";
import k8sclient2 from "./Blog/articles/kubernetes-client-2.md";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Profile />,
    errorElement: <NoMatch />,
  },
  {
    path: "/blog/rot",
    element: <Blog articlePaths={[rotPath]} />,
  },
  {
    path: "/blog/walk",
    element: <Blog articlePaths={[walkPath]} />,
  },
  {
    path: "/blog/kubernetes-clients-pt1",
    element: <Blog articlePaths={[k8sclient1]} />,
  },
  {
    path: "/blog/kubernetes-clients-pt2",
    element: <Blog articlePaths={[k8sclient2]} />,
  },
]);

const theme = createMuiTheme({});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </ThemeProvider>
  );
};

export default App;
